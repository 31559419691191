.pagy.nav {
  @apply flex;
  @apply flex-nowrap;
  @apply justify-center;
}

.pagy.nav a {
  @apply text-blue-600 border-r border-t border-b border-gray-300 text-sm leading-tight font-medium flex-none;
  @apply bg-white;
  @apply px-3 py-2 block;
  @apply self-center;

  &:hover {
    @apply text-gray-900;
    @apply no-underline;
  }

  &:first-child {
    @apply border-l;
    @apply rounded-l;
  }

  &:last-child {
    @apply border-r;
    @apply rounded-r;
  }

  &[aria-disabled="true"] {
    @apply cursor-not-allowed text-gray-500 no-underline;
  }

  &.current {
    @apply text-gray-900;
    @apply bg-slate-100;
  }
}
