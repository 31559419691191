html, body {
  min-height: 100%;
}

.bg-very-slightly-gray {
  background-color: #fcfcfc;
}

.z-9999 {
  z-index: 9999;
}

@layer components {
  .link {
    @apply text-blue-600 cursor-pointer;
  }

  .link--fill {
    @apply fill-blue-600 cursor-pointer;
  }

  .link:hover {
    @apply underline;
  }

  .link--subdued {
    @apply text-blue-400 cursor-pointer;
  }

  .link-action {
    @apply link text-xs font-semibold cursor-pointer;
  }

  .link-action--danger {
    @apply text-red-700 cursor-pointer;
  }

  .link-action:hover {
    @apply underline;
  }
}
