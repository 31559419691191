@layer components {
  .nav-link {
    @apply block pl-3 pr-4 py-2 mt-2 text-sm font-semibold flex-none;
  }

  .nav-link:hover {
    @apply text-gray-900 bg-slate-200;
  }

  .nav-link.active {
    @apply bg-gradient-to-b from-blue-500 to-blue-600;
    @apply text-white;
  }

  .nav-link:focus {
    @apply outline-none ring from-blue-600 to-blue-700;
  }

  .cc-hamburger-menu-link {
      @apply block px-3 py-2 rounded-md text-base font-medium text-gray-400;
  }

  .cc-hamburger-menu-link:hover {
      @apply text-white bg-gray-700 no-underline;
  }
}
