.flatpickr-calendar .flatpickr-current-month {
  .flatpickr-monthDropdown-months,
  .flatpickr-monthDropdown-months:hover {
    min-width: 100px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1rem;
    -webkit-appearance: none;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>');
  }

  .numInputWrapper {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1rem;

    .numInput.cur-year {
      font-weight: 400;
    }
  }
}
