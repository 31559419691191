@layer components {
  .cc-btn--danger {
    @apply bg-red-500 text-center text-white font-medium border border-red-600 rounded-md shadow-sm py-1 px-2 cursor-pointer;
    @apply bg-gradient-to-b from-red-500 to-red-600;
  }

  .cc-btn--primary {
    @apply bg-blue-500 text-center text-white font-medium border border-blue-600 rounded-md shadow-sm py-1 px-2 cursor-pointer;
    @apply bg-gradient-to-b from-blue-500 to-blue-600;
  }

  .cc-btn--primary:hover {
    @apply text-white border-transparent no-underline;
  }

  .cc-btn {
    @apply font-medium rounded-md border border-gray-300 shadow-sm bg-white text-gray-700 text-center py-1 px-2 cursor-pointer;
  }

  .cc-btn:hover {
    @apply no-underline bg-gray-50;
  }

  .cc-btn:disabled, .cc-btn--primary:disabled {
    @apply bg-gray-400 text-gray-100 cursor-not-allowed bg-none border-gray-400;
  }
}
