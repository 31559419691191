@layer components {
  .bubble-green {
    @apply bg-green-50 border border-green-200 text-green-900 p-2 rounded;
  }

  .bubble-green--muted {
    @apply bubble-green;
    @apply bg-opacity-50;
    @apply border-green-100;
  }

  .bubble-red {
    @apply bg-red-50 border border-red-200 text-red-900 p-2 rounded;
  }

  .bubble-yellow {
    @apply bg-yellow-50 border border-yellow-200 text-yellow-900 p-2 rounded;
  }

  .bubble-blue {
    @apply bg-blue-50 border border-blue-200 text-blue-900 p-2 rounded;
  }

  .bubble-gray {
    @apply bg-gray-50 border border-gray-200 text-gray-900 p-2 rounded;
  }

  .rov-sticky-header-bg {
    @apply bg-slate-50/80 backdrop-blur-sm ring-1 ring-slate-900/10;
  }

  .header-bubble__title {
    @apply font-medium text-sm text-gray-700;
  }

  .header-bubble {
    @apply p-1 md:p-2 bg-sky-50/20 border border-sky-700/10 rounded text-sm;
  }

  .data-row {
    @apply space-y-2 md:space-y-0 lg:grid gap-4 border divide-x rounded-md bg-sky-50/40 shadow-sm;
  }

  .data-row__item-title {
    @apply text-xs font-medium uppercase text-gray-600;
  }

  .data-row__item {
    @apply p-1 md:py-4 md:px-2 text-sm;
  }

  .rover-header-grid {
    @apply space-y-4 md:space-y-0 md:grid grid-cols-2 gap-4;
  }

  .rover-page-header-bg {
    @apply bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-100 via-blue-300 to-blue-400;
  }

  .rover-page-header {
    @apply mx-auto flex flex-col gap-y-2 px-6 lg:px-8 py-4;
    @apply shadow-sm;
    @apply bg-white/90;
  }
}
