@layer components {
  .rover-form-label {
    @apply block text-sm leading-5 font-medium text-gray-700;
  }

  .rover-form-label--xs {
    @apply rover-form-label;
    @apply text-xs;
  }

  .rover-text-field-wrapper {
    @apply relative rounded-md shadow-sm;
  }

  .rover-text-field--error {
    @apply border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 block w-full border border-red-500;
  }

  .rover-text-field--normal {
    @apply border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 block w-full bg-white;
  }

  .rover-text-field--normal:disabled {
    @apply text-gray-400;
  }

  trix-editor {
    overflow-y: scroll;
  }

  .rover-disabled-color {
    @apply text-gray-400;
  }

  .rover-checkbox {
    @apply focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded;
  }

  .rover-hint {
    @apply text-sm text-gray-500;
  }

  .rover-select-box {
    @apply block appearance-none w-full border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 shadow-sm;
  }

  .rover-select-box--sm {
    @apply rover-select-box;
    @apply text-sm;
    @apply py-2 px-3;
    min-width: 4rem;
  }

  .rover-radio-button {
    @apply focus:ring-blue-500 text-blue-600 border-gray-300;
  }
}
