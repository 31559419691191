@layer components {
  .badge {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800;
  }

  .badge--error {
    @apply badge;
    @apply bg-red-100 text-red-800;
  }

  .badge--warning {
    @apply badge;
    @apply bg-yellow-100 text-yellow-800;
  }

  .badge--success {
    @apply badge;
    @apply bg-green-100 text-green-800;
  }

  .badge--text-xs {
    @apply badge;
    @apply px-1.5;
    @apply text-xs;
  }
}
