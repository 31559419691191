.rov-select2-wrapper {
  .select2-container {
    border-color: transparent;
  }

  .select2-container--default .select2-selection--single {
    border-color: transparent;
  }
}

.select2-dropdown {
  border-color: #d2d6dc;
}

.select2-search.select2-search--dropdown {
  border-top-width: 1px;
}

.select2-search__field {
  outline-offset: 0;
}