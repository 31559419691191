@layer components {
  .empty-data-set {
    text-align: center;

    border: dashed 2px rgb(128,128,128);
    border-radius: 8px;
    padding: 32px;

    svg {
      fill: rgb(128,128,128);
      width: 128px;
    }

    .empty-data-set-title {
      font-size: 1.6rem;
      font-weight: 400;
      color: rgb(128,128,128);
      width: 50%;
      margin: 0 auto;
    }

    .empty-data-set-text {
      font-size: 1rem;
      color: rgb(128,128,128);
      width: 50%;
      margin: 0 auto;
    }

    .empty-data-set-text + a {
      margin-top: 1rem;
    }
  }
}
