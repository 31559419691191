@layer components {
  .table-print {
    page-break-after: always;
  }

  th {
    text-align: left;
  }

  th.sorting, th.sorting_desc, th.sorting_asc {
    cursor: pointer;
  }

  .sorting_desc:after {
    content: " \002193";
  }
  .sorting_asc:after {
    content: " \002191";
  }

  .rov-table {
    @apply table-auto w-full;
  }

  .rov-table thead th {
    @apply text-sm font-medium text-gray-600 p-1;
  }

  .rov-table tbody tr:nth-child(odd) {
    @apply bg-gray-100;
  }

  .rov-table tbody td {
    @apply p-1;
  }

  .rov-table-cell {
    @apply px-4 py-2 whitespace-nowrap sm:whitespace-normal break-words text-sm text-left font-medium text-gray-900;
  }

  .rov-table__button-bar {
    @apply px-4 py-3 mb-4 flex space-x-2 items-center bg-slate-50/80 backdrop-blur-sm ring-1 ring-slate-900/10;
  }

  .clone-list {
    display: flex;
  }

  .clone-table {
    border-collapse: collapse;
  }

  .clone-table th,
  .clone-table td {
  }

  .dragging {
    z-index: 999;
  }
}
