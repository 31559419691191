@layer components {
  progress[value] {
    @apply h-4;
  }

  progress[value]::-webkit-progress-bar {
    @apply bg-gray-200;
    @apply rounded-md;
    @apply shadow-[inset_0_2px_5px_rgba(0,0,0,0.15)];
  }

  progress[value]::-webkit-progress-value {
    @apply rounded-md;
  }

  progress[value]::-webkit-progress-value {
    @apply bg-gradient-to-b from-sky-500 to-sky-600;
  }

  progress.rov-progress--success[value]::-webkit-progress-value {
    @apply bg-gradient-to-b from-emerald-500 to-emerald-600;
  }

  progress.rov-progress--warning[value]::-webkit-progress-value {
    @apply bg-gradient-to-b from-orange-500 to-orange-600;
  }

  progress.rov-progress--danger[value]::-webkit-progress-value {
    @apply bg-gradient-to-b from-rose-500 to-rose-600;
  }
}
