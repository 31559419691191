@layer base {
  .cc-toolbar-button {
    @apply inline-flex items-center;
    @apply px-2 py-1;
    @apply border border-slate-400/50;
    @apply rounded-md;
    @apply shadow-sm;
    @apply text-sm font-medium;

    @apply hover:no-underline;
    @apply hover:bg-gray-100;
  }
}

@layer components {
  .cc-toolbar-button--default {
    @apply cc-toolbar-button;
    @apply text-gray-700 bg-white hover:bg-gray-50 focus:ring-blue-500;
  }

  .cc-toolbar-button--primary {
    @apply cc-toolbar-button;
    @apply text-white bg-blue-500 hover:bg-blue-700 focus:ring-blue-500;
  }

  .cc-toolbar-button--danger {
    @apply cc-toolbar-button;
    @apply border-red-600 text-white bg-red-500 hover:bg-red-700 focus:ring-red-500;
  }

  .rov-dropdown-item {
    @apply block px-4 py-2 text-sm font-medium text-gray-700 hover:no-underline hover:bg-gray-100 w-full text-left;
  }
}
