@layer components {
  .rov-headline {
    @apply font-semibold text-2xl text-gray-900;
  }

  .rov-headline2 {
    @extend .rov-headline;
    @apply text-lg;
  }

  .rov-subheadline {
    @apply text-sm text-gray-700;
  }

  .rov-caption {
    @apply text-xs font-medium text-gray-600;
  }
}
