@layer components {
  .svg-width-16 svg {
    width: 16px;
  }

  .svg-width-20 svg {
    width: 20px;
  }

  .svg-width-30 svg {
    width: 30px;
  }

  .svg-valign-text-bottom svg {
    vertical-align: text-bottom;
  }

  .svg-valign-bottom svg {
    vertical-align: bottom;
  }

  .rov-icon, .rov-icon svg {
    @apply inline-block;
  }
}
